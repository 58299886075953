import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Sponsor } from '@app/_models';
import { GemsService } from '@app/_services';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.css']
})
export class SponsorsComponent implements OnInit {
  hoofdSponsors: Sponsor[];
  coSponsors: Sponsor[];
  sponsoring: {};
  successvolVerzonden: false;
  nietVerzonden: false;
  naam: string;
  voornaam: string;
  bedrijf: string;
  gsm: string;
  email: string;
  recaptchaResponse: string;
  response: {};

  constructor(private gemsService: GemsService, private viewportScroller: ViewportScroller) { }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    this.getHoofdSponsors();
    this.getCoSponsors();
    this.getSponsoring();
    this.onChangePage.emit('sponsors');
    window.scrollTo(0, 0);
  }

  getHoofdSponsors(): void {
    this.gemsService.getHoofdSponsors()
    .subscribe(
      hoofdSponsors => this.hoofdSponsors = hoofdSponsors
    );
  }

  getCoSponsors(): void {
    this.gemsService.getCoSponsors()
    .subscribe(
      coSponsors => this.coSponsors = coSponsors
    );
  }

  getSponsoring(): void {
    this.gemsService.getSponsoring()
    .subscribe(
      sponsoring => this.sponsoring = sponsoring
    );
  }

  resolved(captchaResponse: string) {
    this.recaptchaResponse = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  goTo(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  sendSponsoringForm(): void {
    this.gemsService.sendSponsorForm(this.recaptchaResponse, this.naam, this.voornaam, this.bedrijf, this.gsm, this.email);
    // .subscribe(
    //   response => this.response = response
    // );
  }
  // if (response.message == "OK") {
  //     this.successvolVerzonden = true;
  //     this.nietVerzonden = false;
  // } else {
  //     this.successvolVerzonden = false;
  //     this.nietVerzonden = true;
  // }

}
