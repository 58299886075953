import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { Covid19Component } from './covid19/covid19.component';
import { PloegenComponent } from './ploegen/ploegen.component';
import { PloegComponent } from './ploeg/ploeg.component';
import { ClubComponent } from './club/club.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { InfoComponent } from './info/info.component';
import { WordLidComponent } from './wordlid/wordlid.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'covid19', component: Covid19Component },
  { path: 'ploegen', component: PloegenComponent },
  { path: 'ploegen/:ploegId', component: PloegComponent },
  { path: 'club', component: ClubComponent },
  { path: 'sponsors', component: SponsorsComponent },
  { path: 'info', component: InfoComponent },
  { path: 'wordlid', component: WordLidComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true, preloadingStrategy: PreloadAllModules, useHash: true, anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
