<main>
    <section class="parallax well text-center submenu" style="padding-bottom: 20px; background-image: url('assets/images/38-back.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                    <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                        <a (click)="goTo('bestuur')" class="btn btn-sm btn-primary">Bestuur en Structuur</a>
                    </div>
                </div>
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                    <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                        <a (click)="goTo('waarden')" class="btn btn-sm btn-primary">Waarden</a>
                    </div>
                </div>
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                    <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                        <a (click)="goTo('visie')" class="btn btn-sm btn-primary">Visie en Werking</a>
                    </div>
                </div>
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                    <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                        <a (click)="goTo('historiek')" class="btn btn-sm btn-primary">Evolutie en Historiek</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="well well--inset-2 bg-default-variant-1" id="bestuur">
        <div class="container">
            <h3>Ons bestuur</h3>

            <div class="row flow-offset-1">
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/jean-marie.jpg" alt="">

                        <div>
                            <p>Jean-Marie Neven</p>

                            <p class="small light">Voorzitter</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0476437740</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/yolanda.jpg" alt="">

                        <div>
                            <p>Yolanda Severens</p>

                            <p class="small light">Secretaris</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0472827292</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/dave.jpg" alt="">

                        <div>
                            <p>Dave Vandickelen</p>

                            <p class="small light">Ondervoorzitter</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0497593650</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/stijn.jpg" alt="">

                        <div>
                            <p>Stijn Vrolix</p>

                            <p class="small light">Penningmeester</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0473897149</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/Neven-Els.png" alt="">

                        <div>
                            <p>Els Neven</p>

                            <p class="small light">Bestuurslid</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0472219679</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/Roebben-Ann.jpg" alt="">

                        <div>
                            <p>Ann Roebben</p>

                            <p class="small light">Bestuurslid</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0475971658</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/sam.jpg" alt="">

                        <div>
                            <p>Sam Vanderstraeten</p>

                            <p class="small light">Bestuurslid</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0472885394</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/Fanton-Kristoff.jpg" alt="">

                        <div>
                            <p>Kristoff Fanton</p>

                            <p class="small light">Bestuurslid</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">0475971658</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                    <div class="box-skin-2">
                        <img src="assets/bestuur/Vanhees-Bram.jpg" alt="">

                        <div>
                            <p>Bram Vanhees</p>

                            <p class="small light">Bestuurslid</p>
                            <p class="small light">
                              <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                              <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">011331230</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="parallax well bg-contrast-variant-1 ng-scope" style="background-image: url('assets/images/35-back.jpg')" id="structuur">
        <div class="container">
            <h3>Structuur</h3>

            <div class="row flow-offset-1">
                <div class="col-md-12">
                    <div class="box-skin-2" style="border: 1px solid #ED1B25; max-width: 100%;">
                        <div>
                            <p style="font-size: 30px; color: #fff;">Dagelijks Bestuur</p>
                        </div>
                    </div>
                    <div class="box-skin-2" style="border: 1px solid #fff; max-width: 100%;">
                        <div style="background-color: #fff; color: #30353c; height: 320px;">
                            <ul class="structuur-list">
                                <li>Algemene werking</li>
                                <li>Visie</li>
                                <li>Beleid</li>
                                <li>Aansturing cellen</li>
                                <li>Beheer VZW</li>
                                <li>1 meeting per maand</li>
                                <li>1 algemene vergadering per jaar</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-4" style="margin-top: 30px;">
                    <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                        <div>
                            <p style="font-size: 30px; color: #fff;">Administratie</p>
                        </div>
                    </div>
                    <div class="box-skin-2" style="border: 1px solid #fff;">
                        <div style="background-color: #fff; color: #30353c; height: 320px;">
                            <ul class="structuur-list">
                                <li>Administratie bond</li>
                                <li>Algemene administratie</li>
                                <li>Communicatie</li>
                                <li>Website</li>
                                <li>Digitalisering</li>
                                <li>Social media</li>
                                <li>1 meeting per maand</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-4" style="margin-top: 30px;">
                    <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                        <div>
                            <p style="font-size: 30px; color: #fff;">Extra-sportief</p>
                        </div>
                    </div>
                    <div class="box-skin-2" style="border: 1px solid #fff;">
                        <div style="background-color: #fff; color: #30353c; height: 320px;">
                            <ul class="structuur-list">
                                <li>Eetdagen</li>
                                <li>Feestcomité</li>
                                <li>6 meetings per jaar</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-4" style="margin-top: 30px;">
                    <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                        <div>
                            <p style="font-size: 30px; color: #fff;">Financieel</p>
                        </div>
                    </div>
                    <div class="box-skin-2" style="border: 1px solid #fff;">
                        <div style="background-color: #fff; color: #30353c; height: 320px;">
                            <ul class="structuur-list">
                                <li>Sponsoring</li>
                                <li>Financieel beheer</li>
                                <li>Boekhouding</li>
                                <li>1 meeting per kwartaal</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-4" style="margin-top: 30px;">
                    <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                        <div>
                            <p style="font-size: 30px; color: #fff;">Sportief</p>
                        </div>
                    </div>
                    <div class="box-skin-2" style="border: 1px solid #fff;">
                        <div style="background-color: #fff; color: #30353c; height: 320px;">
                            <ul class="structuur-list">
                                <li>Sportieve coördinatie</li>
                                <li>Jeugdcoördinatie</li>
                                <li>Clubscheidsrechters</li>
                                <li>Clinics</li>
                                <li>1 meeting per maand</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-4" style="margin-top: 30px;">
                    <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                        <div>
                            <p style="font-size: 30px; color: #fff;">Ploegafgevaardigden</p>
                        </div>
                    </div>
                    <div class="box-skin-2" style="border: 1px solid #fff;">
                        <div style="background-color: #fff; color: #30353c; height: 320px;">
                            <ul class="structuur-list">
                                <li>Overleg met bestuur</li>
                                <li>Doel: wisselwerking verbeteren</li>
                                <li>Ploegafgevaardigden</li>
                                <li>Afvaardiging bestuur</li>
                                <li>4 meetings per jaar</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-4" style="margin-top: 30px;">
                    <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                        <div>
                            <p style="font-size: 30px; color: #fff;">Medisch</p>
                        </div>
                    </div>
                    <div class="box-skin-2" style="border: 1px solid #fff;">
                        <div style="background-color: #fff; color: #30353c; height: 320px;">
                            <ul class="structuur-list">
                                <li>Clubkiné Bram Vanhees</li>
                                <li><a class="text-link" style="color: #ED1B25;" target="_blank" href="http://www.impulskinesitherapie.be">www.impulskinesitherapie.be</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                    <h3>Welkom!</h3>
                    <p>Het success van een vereniging valt en staat bij de onvoorwaardelijke inzet van zijn vrijwilligers.  Wij zijn dan ook zeer dankbaar aan al onze vrijwilligers die zich inzetten voor onze club.</p>
                    <p>Wil jij ook een bepaalde rol binnen ons bestuur of in één van onze cellen opnemen, aarzel dan niet om contact met ons op te nemen. Wij zoeken steeds nieuwe gemotiveerde mensen met zin voor organisatie en een passie voor basketbal die ons bestuur willen versterken.</p>
                    <p>
                      Sportieve Groeten
                    </p>
                    <p>Het Bestuur</p>
                </div>
            </div>
        </div>
    </section>

    <section class="well well--inset-5" id="waarden">
      <div class="container">
                  <h3>Waarden</h3>

                  <p style="font-weight: bold;">TRAPP: Team – Respect – Autonomie – Progressie – Plezant</p>
<p>De jeugdcoaches van Gems Diepenbeek zullen onze spelers en spelertjes naar best vermogen begeleiden en hanteren daarbij de overeengekomen waarden die we op onze trainersvergadering hebben vastgelegd.</p>
<p><span style="font-weight: bold;">Team:</span> we stimuleren de individuele ontwikkeling van elk kind maar altijd in de context van het ploeggebeuren. Samenhorigheid, leren samenwerken en betrokken zijn bij het groepsgebeuren zijn onmisbaar in het proces om een goede ploeg te vormen.</p>
<p><span style="font-style: italic;">Doelstelling: je ploegje een hechte groep te laten vormen waar iedereen aan bod komt, inclusief het organiseren van leuke extra activiteiten waar iedere speler van de ploeg in mee wordt betrokken.</span></p>
<p><span style="font-weight: bold;">Respect:</span> we leren onze spelers aan om respect te hebben voor elkaar, voor de coaches, voor de tegenstanders, voor de arbiters alsook voor bestuursleden die zich belangeloos inzetten. Respect uit zich op allerlei vormen, van stipt te zijn op trainingen en wedstrijden, van een gezonde discipline op trainingen en wedstrijden, van begroeting van coaches er arbiters, van tijdig verwittigen van afwezigheid enz...</p>
<p><span style="font-style: italic;">Doelstelling: we leren aan onze spelers en speelsters aan om op een correcte manier om te gaan met ploegmaats, tegenstanders, arbiters,…  maar ook qua orde en netheid en gebruik van materialen, door zelf te allen tijde een voorbeeld te zijn en hun consequent te wijzen op gedrag wat niet thuis hoort op of rond een basketbalveld. Onder geen enkel beding verlagen we ons tot respectloze uitingen en acties zoals dit te dikwijls bij andere populaire sporten het geval is.</span></p>
<p><span style="font-weight: bold;">Autonomie:</span> we proberen de autonomie van onze spelers te bevorderen zodat ze zowel op basketbalvlak als op menselijk vlak evolueren tot authentieke individuen. Hierin passen we de M-factor met de 3 principes toe: autonomie, binding en competentie.</p>
<p><span style="font-style: italic;">Doelstelling: we stimuleren de autonomie door bijkomende verantwoordelijkheden te geven aan onze spelers in onze trainingen zoals weekkapitein, coachfunctie en arbiterfunctie en moedigen hun aan om initiatief te nemen door via vragen en taken zelf tot oplossingen te komen...</span></p>
<p><span style="font-weight: bold;">Progressie:</span> we zorgen ervoor dat onze spelers zich ontwikkelen om goede basketters te worden. Uiteraard is progressie zowel als individuele speler en als ploeg primordiaal. Onze spelers dienen zich zeker verder te ontwikkelen op vlak van creativiteit / techniek en een gezonde agressieve speelstijl moet aangeleerd worden en kenmerkend worden voor onze jeugdopleiding.</p>
<p><span style="font-style: italic;">Doelstelling: we zorgen voor interessante uitdagende oefeningen zodat spelers hun grenzen verleggen en we gebruiken zoveel mogelijk actieve leertijd zowel op trainingen, als bij wedstrijden (opwarming + pauze). Eveneens verlangen we dat ze op een gezonde sportieve manier leren agressief spelen en we benadrukken dit consequent.</span></p>
<p><span style="font-weight: bold;">Plezant:</span> Fun is cruciaal! We zorgen er voor dat spelers met plezier komen trainen zonder echter dat het een speeltuin wordt. Fun kadert in een duidelijk gestructureerd geheel waar spelers via speel- spelvormen de nodige basketbal skills ontwikkelen.</p>
<p><span style="font-style: italic;">Doelstelling: We zorgen via variatie van trainingsinhoud, spel- en speelvormen dat spelers op een plezante manier hun sport kunnen beleven. We maken hiervoor ook gebruik van de principes van de M-factor (A-B-C).</span></p>

      </div>
    </section>

    <section class="parallax well bg-contrast-variant-1 ng-scope" style="background-image: url('assets/images/35-back.jpg')" id="visie">
        <div class="container">
            <div class="row">
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 text-center text-md-left">
                    <h3>Visie</h3>

                    <p>Gems Diepenbeek wil een hechte familieclub zijn met veel aandacht voor de jeugdwerking en het doorstromen van haar eigen jeugd naar de seniorenploegen.</p>
<p>Gems basketbal wil jongeren uit onze regio kansen geven om hun sportieve ambities waar te maken, elkeen op zijn of haar niveau.</p>

<p>Ook naast het veld wil Gems een gezellige club zijn, waar jong én oud zich thuis voelen.</p>
<p>Daarom organiseren we naast het sportieve aspect binnen de club, ook regelmatig extra-sportieve activiteiten om de vriendschapsbanden onder elkaar aan te sterken.</p>

<p>We moedigen spelers, supporters, coaches, sponsors en andere sympathisanten aan om actief deel te nemen aan het clubgebeuren, waardoor BC Gems Diepenbeek een vereniging is die bruist van energie en enthousiasme!</p>
                </div>
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-6 offset-3">
                    <h3>Werking</h3>
                    <p>Sinds het seizoen 2014-2015 waait er een nieuwe, frisse wind door de club.</p>
<p>Een groep (ex-)spelers en sympathisanten namen het initiatief om het bestaande bestuur te versterken en de club organisatorisch te hervormen. Het doel is hiermee de club op termijn naar een sportief hoger niveau te tillen.</p>
<p>Op sportief, extra-sportief, administratief en financieel vlak werken we er constant aan om het bestuur en de werking van de club te verbeteren waar mogelijk.</p>

<p>Met voorstellen, suggesties, ideeën, ... kan je altijd terecht bij één van de bestuursleden, of via <a class="text-link" href="mailto:contact@gemsdiepenbeek.be">contact@gemsdiepenbeek.be</a>.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="well well--inset-5" id="historiek">
        <div class="container">
          <h3>Evolutie</h3>
          <div style="width:100%;margin-top: 30px;">
        		<canvas id="canvas"></canvas>
        	</div>
        	<script>
        		var lineChartData = {
        			labels: ['2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021'],
        			datasets: [{
        				label: 'Aantal leden',
        				borderColor: 'rgba(236, 28, 34, 1)',
        				backgroundColor: 'rgba(236, 28, 34, 1)',
        				fill: false,
        				data: [
        					123, 148, 180, 215, 254, 300, 291, 307, 352
        				]
        			}, {
        				label: 'Spelende leden',
        				borderColor: 'rgba(41, 45, 52, 1)',
        				backgroundColor: 'rgba(41, 45, 52, 1)',
        				fill: false,
        				data: [
        					86, 106, 133, 163, 194, 233, 229, 236, 276
        				]
        			}, {
        				label: 'Jeugdleden',
        				borderColor: "#aaa",
        				backgroundColor: "#aaa",
        				fill: false,
        				data: [
        					39, 55, 65, 81, 103, 152, 163, 174, 200
        				]
        			}]
        		};

        		window.onload = function() {
        			var ctx = document.getElementById('canvas').getContext('2d');
        			window.myLine = Chart.Line(ctx, {
        				data: lineChartData,
        				options: {
        					responsive: true,
        					hoverMode: 'index',
        					stacked: false,
        					title: {
        						display: false
        					},
        					scales: {
        						yAxes: [{
        							type: 'linear',
        							display: true,
        							position: 'left'
        						}],
        					}
        				}
        			});
        		};
        	</script>
        </div>
    </section>

    <section class="well well--inset-5">
        <div class="container">
                    <h3>Historiek</h3>

                    <p>Het begon allemaal in 1963. Het toenmalige overkoepelende GEMS-hoofdbestuur richtte een basketballclub op. Er werd het eerste jaar gestart met meisjes.
Tot dan bestond er slechts één andere Limburgse basketballclub - nl. Runkst.
Er moest dan ook gestart worden in 4de nationale afdeling. Niemand van de toenmalige speelsters kenden het basketbal maar al na 3 jaar werd er kampioen gespeeld.
Runkst was inmiddels gestopt en Gems was in de buurt veruit de enige Limburgse basketballclub.
Gems groeide uit tot een sterke formatie en genoot de sympathie van vele Diepenbekenaren die bereid waren de verplaatsingen te verzorgen. Het werden verre verplaatsingen - Gent, Kortrijk, Oostende, Marcinelle, Namen, Brussel, enz... - want Gems was intussen doorgestoten naar 1e nationale afdeling.
In die succesrijke periode speelden de Gems-meisjes zelfs éénmaal de halve finale van de Beker van België tegen Aalst.</p>

<p>Josephine Vanaken maakte dit alles actief mee als speelster en was ook aanvoerster van de ploeg.
Zij maakte de klim mee naar de top, maar moest ook lijdzaam toezien hoe de ploeg naar beneden tuimelde. Het werd zelfs zo erg dat op zeker ogenblik - bij gebrek aan speelsters - tijdens een seizoen in 3de nationale moest forfait gegeven worden. Dit was in 1974.</p>

<p>Er werd terug van onderaan gestart met nieuwe jonge speelsters. En al na enkele jaren werden zij kampioen in 1e provinciale. Het verblijf in 3e nationale daarop was evenwel van korte duur en opnieuw werden jonge speelsters aangeworven om de kern uit te breiden. Dat was de komst van de zussen Severens, Rita Vangenechten, e.a.</p>

<p>Een nieuwe kampioenstitel volgde en promotie naar 3e nationale in 1982. Dit keer werd het een verblijf van 6 seizoenen in de nationale rangen (tweemaal 3e nat., tweemaal 2e nat. en weerom tweemaal 3e nat.).
Na nog eens 6 seizoenen in de provinciale reeksen werd Gems weer kampioen maar verkoos niet te stijgen en de kaart eigen jeugd te trekken boven aanwervingen van vreemde speelsters om de ploeg te versterken, teneinde de jeugd kansen te geven en verder te werken aan een eigen opgeleide ploeg.
Het duurde nog eens 2 seizoenen vooraleer Gems in 1996 - met eigen jeugd - weer de kampioenstitel mocht vieren en vanaf dan heeft GEMS haar plaats in de nationale en later landelijke competitie niet meer prijs gegeven. Meer zelfs, de eigen jeugdopleiding heeft geleid tot de vorming van een 2de ploeg in 1e provinciale en zelfs een 3de ploeg in 2e provinciale.</p>

<p>We gaan even terug naar de beginjaren: een jaar na de meisjes - in 1964 - werd eveneens gestart met een jongensploeg. Vermits ook zij geen kaas gegeten hadden van basketball, verliep dit ook niet van een leien dakje.
Het duurde jaren vooraleer de heren zich omhoog konden werken. Zij moesten zelfs wachten op een volgende generatie met o.a. Edgard Vanaken - om de herenploeg nieuw leven in te blazen.
Een kampioenstitel zat er voor onze heren nooit in, maar een promotie naar 1e provinciale wel drie maal: in 1994,1997 en 2005.
In de tijd van Ronnie Bellings beleefde Gems verschillende mooie jaren. Bellings werd destijds 2 maal uitgeroepen tot beste Limburgse speler.
Een vrijwillige daling in het begin van de jaren '90 naar 2de provinciale had enkel als doel eigen jeugdtalent te laten ontplooien. </p>

<p>De herenploeg kende sedertdien moeilijke jaren, waarbij verschillende seizoenen spelers moesten worden aangeworven bij gebrek aan voldoende eigen jeugd. Ook in die perioden kon Gems nooit een kampioenstitel behalen.</p>

<p>Met coach Johan Wolk kende onze herenploeg een schitterende heropleving. Hij bouwde bij Gems aan een wilskrachtige, vechtlustige en een samenhangende basketbalploeg.</p>

<p>In 2005-2006 behield Gems zijn plaats in 1e provinciale op de valreep. De in de laatste jaren verworven wilskracht en vechtlust, kwam hierbij goed van pas. Ondertussen stonden enkele eigen opgeleide jongeren klaar en kon er zelfs voor het eerst een extra ploeg opgestart worden in 3e provinciale.
Het verblijf in 1e duurde 5 jaar en onze B ploeg kon na 1 jaar reeds een reeks hoger aantreden. Ondertussen speelt onze A ploeg al 6 jaar in 2e provinciale. Onze B ploeg treedt momenteel aan in 4e provinciale. </p>

<p>Sinds het seizoen 2014-2015 is er een nieuw bestuur in de club en genieten onze erevoorzitster Josephine Vanaken en eresecretaris Rita Vangenechten van wat extra vrije tijd.</p>

<p>Onder impuls van dit vernieuwde bestuur kreeg de club terug wat meer zuurstof en groeide het aantal leden gestaag. Er werd vooral gewerkt aan een heropbouw van onder uit. Zo werd er een balspeeltuin opgericht om kinderen van 4 en 5 jaar reeds de eerste kneepjes van het basket bij te brengen en werd er hard gewerkt om de kloof bij de jeugd naar de seniors terug te dichten. Hier gingen enkele jaren overheen, en sinds seizoen 2018-2019 beschikken we terug over jeugploegen vanaf U8 t.e.m. U19. Het ledenaantal is ondertussen opgelopen tot meer dan 300.
        </div>
    </section>
    <section class="well well--inset-5">
        <div class="container">
                    <h3>Historiek in cijfers</h3>
                    <div class="row offset-1 flow-offset-2">
                        <div class="table-responsive">
<table class="rwd-table">
  <tr>
    <th>Jaar</th>
    <th>Heren A</th>
    <th>Heren B</th>
    <th>Dames A</th>
    <th>Dames B</th>
    <th>Dames C</th>
  </tr>
<tr class="nodd"><td>1979-1980</td><td>1<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1980-1981</td><td>1<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1981-1982</td><td>1<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td> > kampioen!</td><td></td></tr>
<tr class="nodd"><td>1982-1983</td><td>1<sup>e</sup> prov</td><td></td><td>3<sup>e</sup> nat.	</td><td></td><td></td></tr>
<tr class="nodd"><td>1983-1984</td><td>1<sup>e</sup> prov</td><td></td><td>3<sup>e</sup> nat.	</td><td></td><td></td></tr>
<tr class="nodd"><td>1984-1985</td><td>1<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> nat.	</td><td></td><td></td></tr>
<tr class="nodd"><td>1985-1986</td><td>1<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> nat.	</td><td></td><td></td></tr>
<tr class="nodd"><td>1986-1987</td><td>1<sup>e</sup> prov</td><td></td><td>3<sup>e</sup> nat.	</td><td></td><td></td></tr>
<tr class="nodd"><td>1987-1988</td><td>1<sup>e</sup> prov</td><td></td><td>3<sup>e</sup> nat.	</td><td></td><td></td></tr>
<tr class="nodd"><td>1988-1989</td><td>1<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1989-1990</td><td>1<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1990-1991</td><td>1<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1991-1992</td><td>2<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1992-1993</td><td>2<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1993-1994</td><td>2<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td> > kampioen!</td><td></td></tr>
<tr class="nodd"><td>1994-1995</td><td>1<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td></td><td></td></tr>
<tr class="nodd"><td>1995-1996</td><td>2<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td> > kampioen!</td><td></td></tr>
<tr class="nodd"><td>1996-1997</td><td>2<sup>e</sup> prov</td><td></td><td>3<sup>e</sup> nat.	</td><td></td><td></td></tr>
<tr class="nodd"><td>1997-1998</td><td>1<sup>e</sup> prov</td><td></td><td>3<sup>e</sup> nat.	</td><td>1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>1998-1999</td><td>2<sup>e</sup> prov</td><td></td><td>3<sup>e</sup> nat.</td><td>	1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>1999-2000</td><td>2<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> nat.</td><td>	1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>2000-2001</td><td>2<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> nat.	</td><td>1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>2001-2002</td><td>2<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> nat.</td><td>	1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>2002-2003</td><td>2<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> land.</td><td>1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>2003-2004</td><td>2<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> land.</td><td>1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>2004-2005</td><td>2<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td></td></tr>
<tr class="nodd"><td>2005-2006</td><td>1<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2006-2007</td><td>1<sup>e</sup> prov</td><td>3<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2007-2008</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov	</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2008-2009</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2009-2010</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2010-2011</td><td>2<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2011-2012</td><td>2<sup>e</sup> prov</td><td>3<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2012-2013</td><td>2<sup>e</sup> prov</td><td>3<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2013-2014</td><td>2<sup>e</sup> prov</td><td>4<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2014-2015</td><td>2<sup>e</sup> prov</td><td>4<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2015-2016</td><td>2<sup>e</sup> prov</td><td>4<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2016-2017</td><td>2<sup>e</sup> prov</td><td>4<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2017-2018</td><td>2<sup>e</sup> prov</td><td>4<sup>e</sup> prov</td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2018-2019</td><td>2<sup>e</sup> prov</td><td></td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2019-2020</td><td>2<sup>e</sup> prov</td><td></td><td>2<sup>e</sup> land</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td></tr>
<tr class="nodd"><td>2020-2021</td><td>2<sup>e</sup> prov</td><td>4<sup>e</sup> prov</td><td>1<sup>e</sup> prov</td><td>2<sup>e</sup> prov</td><td></tr>
</table>
                </div>
            </div>
        </div>
    </section>
</main>
