import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {

  constructor() { }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    this.onChangePage.emit('covid19');
    window.scrollTo(0, 0);
  }

}
