<section class="well well--inset-2 bg-default-variant-2" id="inschrijven">
  <div class="container">
      <div class="row flow-offset-1">
          <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
              <h3>Word lid</h3>
              <p>
                Wil je ook deel uitmaken van onze basketbal familie? Dit kan, kinderen onder de 10 jaar kunnen gewoon naar één van onze peanuts of balspeeltuin trainingen komen op zaterdag. Meer info vind je op onze ploegenpagina. Voor kinderen ouder dan 10 jaar neem je even contact op met ons via <a class="text-link" href="mailto: contact@gemsdiepenbeek.be">email</a>. Wij zullen dan de mogelijkheden bekijken om je door te sturen naar een ploeg.
              </p>
              <p>
                <b>Let wel: voor je aan de eerste training begint moet je alvast de eerste stap afronden van de inschrijvingsprocedure (inschrijven in ons ledenbestand). Dit is om in orde te zijn met de verzekering.</b> Heb je na 5 gratis trainingen de microbe te pakken? Rond dan onderstaande procedure verder af.
              </p><br/>
              <ol style="list-style-type: decimal">
                <li style="margin-left: 40px; padding-left: 30px;">Schrijf je in, in ons ledenbestand via <a class="text-link" href="https://admin.gemsdiepenbeek.be/nieuweleden" target="_blank">deze link</a>.</li>
                <li style="margin-left: 40px; padding-left: 30px;">Betaal het lidgeld overeenkomstig met jouw ploeg, zie info pagina. Dit lidgeld kan je storten op BE26 7512 0463 0129 met vermelding van de naam van het lid.</li>
                <li style="margin-left: 40px; padding-left: 30px;">Inschrijving bij Basketbal Vlaanderen via <a class="text-link" href="https://vblbeid.wisseq.eu/" target="_blank">https://vblbeid.wisseq.eu/</a>:
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Inloggen met de identiteitskaart van het lid.</li>
                    <li>Klik op "aanmelden bij een club".</li>
                    <li>Zoek naar onze club "BC Gems Diepenbeek" en klik op de knop "kiezen".</li>
                    <li>Duid aan of het om een spelend of administratief lid gaat.</li>
                    <li>E-mail adres dient verplicht ingevuld te worden.</li>
                    <li>De privacy voorwaarden dienen geaccepteerd te worden.</li>
                    <li>Indien het lid minderjarig is, zal er gevraagd worden om de identiteitskaart van één van de ouders of voogd in te steken.</li>
                    <li>Er zal een e-mail verzonden worden naar het lid.</li>
                  </ul>
                </li>
                <li style="margin-left: 40px; padding-left: 30px;">Indien je deelneemt aan competitie wedstrijden moet je een medische keuring ondergaan, dit kan simpelweg door de <a class="text-link" href="https://gemsdiepenbeek.be/docs/medischgetuigschrift-2020-2021.pdf" target="_blank">medische kaart</a> af te printen en te laten invullen door een dokter.  Bezorg de ingevulde medische kaart aan je coach of iemand van het bestuur.</li>
              </ol>
            </div>
        </div>
    </div>
</section>
