<section class="text-center" id="photo-header">
    <div style="padding: 20px 20px 200px 20px; background-image: url('https://www.gemsdiepenbeek.be/images/random-home/U12-2019-2.jpg'); background-size: cover; background-position: center; height: 100%;">
      <div class="trooper">
        <a href="https://trooper.be/bcgemsdiepenbeek" target="_blank"><img class="trooper-image" src="assets/images/trooper.png"/></a>
      </div>
      <h2 class="first-banner-item" style="color: #333; background-color: #fff; opacity: 0.8; padding: 50px 50px 10px 50px; font-size: 30px; width: 350px; margin-bottom: 20px;">
        {{firstNewsitem?.subtitel}}
        <br class="br-none br-sm-visible">
        <a (click)="goTo(firstNewsitem.tag);" class="btn btn-sm btn-primary" style="cursor: pointer; position: relative; text-align: center;">Lees meer</a>
      </h2>
      <h2 style="color: #fff; background-color: #ED1B25; opacity: 0.8; padding: 50px 50px 10px 50px; font-size: 30px; width: 350px; margin-bottom: 25px;">
        {{secondNewsitem?.subtitel}}
        <br class="br-none br-sm-visible">
        <a (click)="goTo(secondNewsitem.tag);" class="btn btn-sm btn-opposite" style="cursor: pointer; position: relative; text-align: center;">Lees meer</a>
      </h2>
      <div style="height: 200px; position: absolute; overflow: hidden;">
        <div class="sponsor" (click)="openSponsorPage(sponsor)" *ngFor="let sponsor of randomHoofdsponsors">
          <img [src]="'assets/sponsors/' + sponsor.id + '-thumb.png'" style="width: 200px; height: 92px;"/>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
</section>
<section class="well" data-mobile="true" id="dezeweek">
    <div class="container">
        <h3><div (click)="previousWeek()" class="previous-week"></div>Deze week<div (click)="nextWeek()" class="next-week"></div></h3>
        <div class="row offset-1 flow-offset-2">
            <div class="table-responsive">
                <table class="rwd-table">
                    <tr>
                        <th>Datum</th>
                        <th>Tijd</th>
                        <th>Thuis</th>
                        <th>Uit</th>
                        <th class="min-width-500">Uitslag</th>
                        <th class="min-width-500">Sporthal</th>
                        <th class="min-width-500">DWF</th>
                    </tr>
                    <tr class="nodd" *ngFor="let match of matches">
                        <td class="centertext">{{match.datum}}</td>
                        <td>{{match.uur}}</td>
                        <td style="font-weight: bold;" *ngIf="match.thuisWedstrijd">{{match.thuisTeam}}</td>
                        <td *ngIf="!match.thuisWedstrijd">{{match.thuisTeam}}</td>
                        <td style="font-weight: bold;" *ngIf="!match.thuisWedstrijd">{{match.uitTeam}}</td>
                        <td *ngIf="match.thuisWedstrijd">{{match.uitTeam}}</td>
                        <td class="min-width-500">{{match.uitslag}}</td>
                        <td class="min-width-500">
                          <a href="https://kalender.gemsdiepenbeek.be/sporthal.php?id={{match.guid}}" target="_blank"><img style="height: 20px;" src="assets/images/location.png"/></a>
                        </td>
                        <td class="min-width-500">
                          <a class="text-link" href="https://vblweb.wisseq.eu/Home/MatchDetail?wedguid={{match.guid}}" target="_blank">DWF</a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div style="margin-top: 10px; float: right;">
          <a href="https://kalender.gemsdiepenbeek.be" target="_blank" class="btn btn-sm btn-primary">Volledige kalender</a>
        </div>
    </div>
</section>
<section class="parallax well bg-contrast-variant-1" style="background-image: url('assets/images/35-back.jpg')" data-mobile="true" id="agenda">
    <div class="container text-center text-md-left">
        <h3>Agenda 2020-2021</h3>

        <div class="row offset-1 flow-offset-2">
            <div class="col-xs-6 col-md-4" *ngFor="let activiteit of activiteiten">
                <div class="event-post">
                    <!-- <time datetime="{{activiteit.datum}}">{{activiteit.datum}}</time> -->
                    <div class="datetime">{{activiteit.datum}}</div>
                    <h6>{{activiteit.naam}}</h6>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="well well--inset-5" id="nieuws">
    <div class="container">
        <h3>Nieuws</h3>
        <div class="row offset-6" *ngFor="let newsitem of newsitems">
            <div class="col-sm-offset-1 col-sm-10" id="{{newsitem.tag}}">
              <div class="box_cnt">
                  <div style="float: left;">
                      <img [src]="newsitem.icoon" alt="" style="width: 270px; margin-right: 30px;">
                  </div>
                  <h6>{{newsitem.titel}}</h6>

                  <p [innerHTML]="newsitem.tekst"></p>
              </div>
            </div>
        </div>
    </div>
</section>
<section class="well well--inset-4" id="sponsor-tab" style="padding-top: 0;">
    <div class="container">
        <div class="resp-tabs">
            <div class="resp-tabs-container text-center">
                <!-- Tab 1 -->
                <div class="row flow-offset-1">
                    <div *ngFor="let sponsor of sponsors" style="width: 140px; height: 65px; margin: 10px; float: left;" class="col-xs-offset-2 col-xs-8 col-sm-offset-0 col-sm-6 col-md-3">
                        <div class="box-skin-2" style="width: 140px; height: 65px;">
                            <a [href]="sponsor.website" target="_blank">
                                <img [src]="'assets/sponsors/' + sponsor.id + '.png'" alt="" style="width: 140px; height: 65px;">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
