<main>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
              <h3>Covid-19 Plan - Update 13/10/2020</h3>
              <p>Om alles op een veilige manier te laten verlopen, hebben we een Covid-19 plan uitgewerkt.</p>
              <p>Wij willen benadrukken dat deelname aan alle activiteiten op vrijwillige basis is. Wie zich niet 100% veilig voelt bij de genomen maatregelen, is niet verplicht om deel te nemen. Gelieve dan wel de coach hiervan te verwittigen.</p>
              <p>Onderstaand overzicht bundelt de richtlijnen van de gemeente, de basketbalbond en Sport Vlaanderen. Deze regels kunnen op ieder moment aangepast worden indien er zich wijzigingen voordoen bij één van de instanties.</p>
              <p>Onze club kan en zal niet optreden als politieagent, hiervoor rekenen wij op de burgerzin van de leden om de regels te respecteren.</p>
            </div>
        </div>
    </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Algemene regels</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Wanneer iemand getest moet worden, mag deze speler niet komen basketten totdat het resultaat gekend is.</li>
                    <li>Wanneer een speler positief test, worden alle basketbalactiviteiten van de betreffende ploeg opgeschort. De ploeggenoten nemen contact op met de huisarts, die verder raad zal geven.</li>
                    <li>Hou je aan de veiligheidsregels opgelegd door de overheden.</li>
                    <li>Ben je ziek, blijf dan thuis!</li>
                    <li>Hou je aan de afstandsregel van 1,5 m indien mogelijk.</li>
                    <li>Was en ontsmet je handen regelmatig.</li>
                    <li>Bij het betreden van het gebouw, ben je verplicht je handen te desinfecteren.</li>
                    <li>Draag een mondmasker tot je begint met sporten en bij iedere verplaatsing buiten het speelveld. Dit geldt voor iedereen vanaf 12 jaar.</li>
                    <li>Vermijd onnodig contact, geef elkaar geen hand, high 5,...</li>
                    <li>Als je de sporthal betreedt, volg dan de pijlen met de looprichting.</li>
                    <li>Kom niet te vroeg naar de sporthal, zo vermijden we onderling contact tussen verschillende groepen.</li>
                    <li>De toegang tot de sporthal verloopt via de deur "buitensporters", aan de achterkant van de sporthal.</li>
                    <li>Het gebouw verlaten, doet men in groep via de voorkant (voordeur).</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Spelers</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Vanaf 14/10/2020 zal de automatische voordeur terug open gaan. De sporters mogen 5 minuten voor aanvang van de training individueel en rechtstreeks naar binnen gaan. De trainer wacht hen op aan de deur van het gehuurde sportterrein (zij moeten dus niet meer buiten verzamelen bij de trainers). De kinderen van de balspeeltuin en peanuts zullen ook aan de voordeur opgewacht worden.</li>
                    <li>Na het sporten, word je onder begeleiding van je coach naar de uitgang gebracht.</li>
                    <li>Ploegen vanaf U14 moeten contactloos trainen. Er mag geen contact zijn en de 1,5m dient gerespecteerd te worden.</li>
                    <li>De ballen en andere materialen mogen pas gebruikt worden nadat de coach ze ontsmet heeft.</li>
                    <li>Iedere speler heeft zijn eigen drinkbus, zorg ervoor dat deze duidelijk gemarkeerd is.</li>
                    <li>De kleedkamers zijn gesloten, kleed je dus al aan voor je naar de sporthal komt.</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Coaches</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Vanaf 14/10/2020 zal de automatische voordeur terug open gaan. De sporters mogen 5 minuten voor aanvang van de training individueel en rechtstreeks naar binnen gaan. De trainer wacht hen op aan de deur van het gehuurde sportterrein (je hoeft dus niet meer buiten te verzamelen). De kinderen van de balspeeltuin en peanuts zullen ook aan de voordeur opgewacht worden.</li>
                    <li>Na het sporten begeleid je de groep terug naar de uitgang. In- en uitgang via de automatische voordeur van de sporthal!</li>
                    <li>Betreed het terrein pas wanneer de vorige groep het heeft verlaten.</li>
                    <li>Ontsmet de ballen en andere materialen voor en na de training of wedstrijd. Het ontsmettingsmiddel wordt voorzien door de sportdienst.</li>
                    <li>Ploegen vanaf U14 dienen contactloos te trainen. Er mag geen contact zijn en de 1,5m dient gerespecteerd te worden.</li>
                    <li>Als jouw ploeg de laatste is, zorg er dan voor dat het ballenrek gesloten wordt.</li>
                    <li>Doe geen team yell bij aanvang van een wedstrijd, time-out,... </li>
                    <li>Vul ten laatste na de training de aanwezigheden in via ons systeem, zodat we voldoen aan de registratie eisen van de overheid.</li>
                    <li>Wanneer je een training niet kan, dient deze training afgelast te worden.</li>
                    <li>Zorg dat je alle maatregelen zeer goed kent, als coach ben je het eerste aanspreekpunt wanneer er vragen zijn.</li>
                    <li>Vul het digitale wedstrijdformulier op voorhand in zodat dit niet meer dient te gebeuren aan de wedstrijdtafel.</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Ouders en supporters</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>De ouders wachten hun kind op ter hoogte van het fietsenrek en niet op de parking om gevaarlijke situaties te vermijden.</li>
                    <li>Een beperkt aantal supporters worden toegelaten bij jeugdwedstrijden. Er worden maximum 12 toeschouwers (kinderen &#60; 12 jaar niet meegeteld) per ploeg toegelaten.</li>
                    <li>Supporters moeten zich verplicht registreren in het register dat ter beschikking gesteld zal worden.</li>
                    <li>Er worden geen tribunes geplaatst, er staan per plein de nodige stoelen. Deze stoelen mogen gebruikt worden mits ze eerst te ontsmetten. Na gebruik dienen ze terug opgeruimd te worden.</li>
                    <li>Supporters mogen 10 minuten voor aanvang van de wedstrijd binnen. Samenscholingen van supporters in de gangen en de inkomhal zijn verboden.</li>
                    <li>Mondmaskerplicht vanaf het betreden van de sporthal. We vragen ook om zoveel mogelijk in de sporthal te blijven tot het einde van de wedstrijd.</li>
                    <li>Neem de lichaamstemperatuur van je kind thuis. Indien die te hoog is (> 37,5 graden), moet je kind thuis blijven.</li>
                    <li>Communiceer enkel digitaal met de coach.</li>
                    <li>Wanneer de coach niet kan of ziek is, zal er niet voor vervanging gezorgd worden en zal de training afgelast worden.</li>
                    <li>Elke speler heeft zijn eigen wedstrijduitrusting en moet deze zelf wassen, gelieve de juiste wasinstructies hiervoor te volgen.</li>
                    <li>Traktaties na de wedstrijd mogen niet doorgaan.</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Tafelofficials</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Draag een mondmasker.</li>
                    <li>Zorg ervoor dat de tafel en het materiaal ontsmet voor en na de wedstrijd.</li>
                    <li>Indien je je niet veilig voelt bij het uitoefenen van je taak als official, mag je deze altijd weigeren.  Het is dan aan de thuisploeg om deze taken op te nemen.</li>
                    <li>Zorg ervoor dat de vervangingsstoel op 1,5m van de tafel staat.</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Ploegafgevaardigden</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Draag een mondmasker.</li>
                    <li>Wij willen jullie (of de ouder die tijdens de wedstrijd afgevaardige is) vragen om ons te helpen toezien op het naleven van de maatregelen. Bij problemen, contacteer iemand van het bestuur.</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Wat te doen bij een besmetting</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Verwittig onmiddellijk de coach en het bestuur telefonisch.</li>
                    <li>De hele ploeg zal een bericht krijgen hierover.</li>
                    <li>Iedere speler/ouder dient contact op te nemen met zijn/haar huisarts om dit medisch verder op te volgen.</li>
                    <li>De hele ploeg gaat minstens 2 weken in quarantaine, de trainingen en wedstrijden gedurende deze periode zullen geannuleerd worden.</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" style="padding-bottom: 30px; padding-top: 0;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h4 style="margin-bottom: 30px;">Cafetaria Knooppunt 100</h4>
                <!-- <p> -->
                  <ul style="list-style-type: square; margin-left: 20px;">
                    <li>Iedereen moet registreren.</li>
                    <li>1,5 meter afstand houden.</li>
                    <li>Rugzakken en sporttassen indien mogelijk in de auto zetten.</li>
                    <li>Er kan niet aan de toog afgehaald worden.</li>
                    <li>Bubbels van maximum 10 personen.</li>
                    <li>Re mogen geen tafels of stoelen verschoven worden tenzij de uitbaters dit toelaten.</li>
                    <li>Geen traktatie na de wedstrijd.</li>
                    <li>Vanaf 12 jaar is een mondmasker verplicht tenzij je zit.</li>
                    <li>Iedereen moet aan tafel zitten, tenzij je naar het toilet gaat.</li>
                    <li>Jammer genoeg vol = vol.</li>
                  </ul>
                <!-- </p> -->
              </div>
          </div>
      </div>
  </section>
</main>
