  <section class="parallax well text-center submenu" style="background-image: url('assets/images/38-back.jpg'); padding-bottom: 20px;">
      <div class="container">
          <div class="row">
              <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                  <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                      <a (click)="goTo('info')" class="btn btn-sm btn-primary">Nuttige info</a>
                  </div>
              </div>
              <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                  <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                      <a (click)="goTo('lidgeld')" class="btn btn-sm btn-primary">Lidgeld</a>
                  </div>
              </div>
              <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                  <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                      <a (click)="goTo('recupereer')" class="btn btn-sm btn-primary">Recupereer lidgeld</a>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-3" id="info">
      <div class="container text-center text-md-left">
          <h3>Nuttige info</h3>
          <div class="row flow-offset-2">
              <div class="col-xs-6 col-md-4" *ngFor="let info of infos">
                  <div class="event-post" style="height: 260px; padding-top: 40px;">
                      <h6>{{info.titel}}</h6>

                      <p>{{info.beschrijving}}</p>
                      <a [href]="info.link" target="_blank" class="btn btn-sm btn-primary">Download</a>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="parallax well bg-contrast-variant-1 ng-scope" data-parallax-bg-image="images/38-back.jpg" id="lidgeld">
      <div class="container">
          <h3>Lidgeld - 2020-2021</h3>

          <div class="row flow-offset-1">
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">Balspeeltuin</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>1 training / week</li>
                              <li>&nbsp;</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">80</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">Peanuts</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>1 training / week</li>
                              <li>&nbsp;</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">100</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">U8</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>2 trainingen / week</li>
                              <li>1 wedstrijd / week</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">180</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">U10 U12</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>2 trainingen / week</li>
                              <li>1 wedstrijd / week</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">240</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">U14 U16 U18</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>2 trainingen / week</li>
                              <li>1 wedstrijd / week</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">280</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">Seniors A</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>2 trainingen / week</li>
                              <li>1 wedstrijd / week</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">330</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">Seniors B</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>1 training / week</li>
                              <li>1 wedstrijd / week</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">270</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3">
                  <div class="box-skin-2" style="border: 1px solid #ED1B25;">
                      <div>
                          <p style="font-size: 30px; color: #fff;">Recreatie</p>
                      </div>
                  </div>
                  <div class="box-skin-2" style="border: 1px solid #fff;">
                      <div style="background-color: #fff; color: #30353c;">
                          <ul class="lidgeld-list">
                              <li>1 training / week</li>
                              <li>&nbsp;</li>
                              <li>
                                <span style="vertical-align: top;">&euro;</span>
                                <span style="font-size: 50px;">100</span>
                                <span style="vertical-align: bottom;"> / jaar</span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                  <p>Lidgelden moeten voor de helft betaald worden vóór het seizoen, nl. 31/05. Het saldo moet betaald worden vóór 31/08 bij het begin van het seizoen.</p>
              </div>
          </div>
      </div>
  </section>
  <section class="well well--inset-2 bg-default-variant-2" id="recupereer" style="padding-bottom: 30px;">
    <div class="container">
        <div class="row flow-offset-1">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
                <h3>Recupereer een stukje van je lidgeld!</h3>
                <p>Aangezien sporten een positieve bijdrage levert aan onze gezondheid zijn er bepaalde ziekenfondsen die tegemoet komen in het lidgeld. Zo kan je een klein stukje van je lidgeld recupereren. Download hier de formulieren van jouw ziekenfonds, we hebben alvast enkele gegevens voor jou ingevuld.<br/>
Staat jouw ziekenfonds er niet bij, vraag het dan even na of bezoek de website van jouw ziekenfonds.<br/>

<a target='_blank' href='docs/lidgeld_formulier_cm.pdf' class="btn btn-sm btn-primary">CM</a>
<a target='_blank' href='docs/lidgeld_formulier_partena.pdf' class="btn btn-sm btn-primary">Partena</a>
<a target='_blank' href='docs/lidgeld_formulier_voorzorg.pdf' class="btn btn-sm btn-primary">De voorzorg</a>
<a target='_blank' href='docs/lidgeld_formulier_liberale_mutualiteit.pdf' class="btn btn-sm btn-primary">Liberale mutualiteit</a><br/><br/>

Bezorg de invulbladeren aan iemand van het bestuur en wij vullen het nodige verder in!!</p>
              </div>
          </div>
      </div>
  </section>
