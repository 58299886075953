import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-wordlid',
  templateUrl: './wordlid.component.html',
  styleUrls: ['./wordlid.component.css']
})
export class WordLidComponent implements OnInit {

  constructor() { }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    this.onChangePage.emit('wordlid');
    window.scrollTo(0, 0);
  }

}
