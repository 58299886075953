<section class="parallax well text-center submenu" style="padding-bottom: 20px; background-image: url('assets/images/38-back.jpg')">
    <div class="container">
        <div class="row">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('hoofdsponsors')" class="btn btn-sm btn-primary">Hoofdsponsors</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('co-sponsors')" class="btn btn-sm btn-primary">Co-sponsors</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('word-sponsor')" class="btn btn-sm btn-primary">Word sponsor</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('mogelijkheden')" class="btn btn-sm btn-primary">Shirtsponsoring</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="well well-inset-4" id="hoofdsponsors" style="padding-bottom: 0;">
    <div class="container">
        <div class="resp-tabs">
            <h3 style="margin-bottom: 40px;">Hoofdsponsors</h3>
            <div class="resp-tabs-container text-center">
                <div class="row flow-offset-1">
                    <div *ngFor="let hoofdSponsor of hoofdSponsors" class="col-xs-offset-2 col-xs-8 col-sm-offset-0 col-sm-6 col-md-3">
                        <div class="box-skin-2" style="height: 150px;">
                            <a [href]="hoofdSponsor.website" target="_blank">
                                <img [src]="'assets/sponsors/' + hoofdSponsor.id + '-thumb.png'" alt="" style="height: 135px;">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="well well-inset-4" id="co-sponsors">
    <div class="container">
        <div class="resp-tabs">
            <h3 style="margin-bottom: 40px;">Co-sponsors</h3>
            <div class="resp-tabs-container text-center">
                <!-- Tab 1 -->
                <div class="row flow-offset-1">
                    <div *ngFor="let sponsor of coSponsors" class="col-xs-offset-2 col-xs-8 col-sm-offset-0 col-sm-6 col-md-3">
                        <div class="box-skin-2" style="height: 150px;">
                            <a [href]="sponsor.website" target="_blank">
                                <img [src]="'assets/sponsors/' + sponsor.id + '-thumb.png'" alt="" style="height: 135px;">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<script src='https://www.google.com/recaptcha/api.js?hl=nl'></script>
<section class="well well--inset-2 bg-default-variant-2" id="word-sponsor">
  <div class="container">
      <div class="row flow-offset-1">
          <div class="col-xs-offset-1 col-xs-10 col-sm-offset-0 col-sm-6 col-md-12 offset-3" style="margin-top: 40px;">
              <h3>Word sponsor</h3>
              <p>
                Om de kwaliteit van onze club te kunnen garanderen, kunnen wij rekenen op financiële steun van onze sponsors. Dit is van essentieel belang. Wij zijn dan ook altijd op zoek naar extra sponsors. Wil jij ook graag sponsor worden van onze club en wil jij weten wat wij voor uw onderneming kunnen betekenen? Vul dan je contactgegevens in en wij nemen zo spoedig mogelijk contact met u op.
              </p>
              <p>
                Klik <a href="docs/Sponsorbrochure-1.1.pdf" target="_blank" class="text-link">hier</a> voor onze sponsorbrochure.
              </p>
              <form class="rd-mailform" novalidate name="sponsorForm" id="sponsorForm">
                  <p *ngIf="successvolVerzonden" style="color: green;">Successvol verzonden</p>
                  <p *ngIf="nietVerzonden" style="color: red;">Problemen bij het verzenden, vink "Ik ben geen robot" aan.</p>
                  <fieldset>
                      <div class="nowrap-md">
                          <label data-add-placeholder="">
                              <input type="text"
                                     name="naam"
                                     [(ngModel)]="naam"
                                     placeholder="Naam*" required/>
                          </label>
                          <label data-add-placeholder="">
                              <input type="text"
                                     name="voornaam"
                                     [(ngModel)]="voornaam"
                                     placeholder="Voornaam*" required/>
                          </label>
                          <label data-add-placeholder="">
                              <input type="text"
                                     name="bedrijf"
                                     [(ngModel)]="bedrijf"
                                     placeholder="Bedrijf*" required/>
                          </label><br/>
                          <label data-add-placeholder="">
                              <input type="text"
                                     name="gsm"
                                     [(ngModel)]="gsm"
                                     placeholder="GSM nr*" required/>
                          </label>
                          <label data-add-placeholder="">
                              <input type="email"
                                     name="email"
                                     [(ngModel)]="email"
                                     placeholder="E-mail*" required/>
                          </label><br/>
                          <label data-add-placeholder="">
                              <re-captcha (resolved)="resolved($event)" siteKey="6LcCAjgUAAAAAIo_MRCTIgmiDP6LXWK6WzQoezqJ"></re-captcha>
                          </label>
                      </div>
                      <div class="mfControls btn-group text-center text-md-left">
                          <button ng-disabled="sponsorForm.$invalid" ng-class="{disabled: sponsorForm.$invalid}" class="btn btn-sm btn-primary" ng-click="send()">Verstuur</button>
                      </div>
                      <div class="mfInfo"></div>
                  </fieldset>
              </form>
            </div>
        </div>
    </div>
</section>
<section class="well" data-mobile="true" id="mogelijkheden">
    <div class="container">
        <h3 style="margin-bottom: 40px;">Shirtsponsoring</h3>
        <div class="row offset-1 flow-offset-2">
            <div class="table-responsive">
                <table class="rwd-table" *ngFor="let item of sponsoring | keyvalue" style="margin-bottom: 30px;">
                      <tr>
                        <th colspan="5" style="font-weight: bold;">{{item.key}}</th>
                      </tr>
                      <tr>
                          <th>Kledij</th>
                          <th>Plaats</th>
                          <th>Beschikbaar vanaf</th>
                          <th>Prijs</th>
                      </tr>
                      <tr class="nodd" *ngFor="let value of item.value">
                          <td class="centertext">{{value.kledijType}}</td>
                          <td class="centertext">{{value.kledijPlaats}}</td>
                          <td class="centertext">{{value.beschikbaarVanaf}}</td>
                          <td class="centertext">{{value.prijs}}</td>
                      </tr>
                </table>
            </div>
        </div>
    </div>
</section>
