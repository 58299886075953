import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GemsService } from '@app/_services';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  infos: [];

  constructor(private gemsService: GemsService, private viewportScroller: ViewportScroller) { }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    this.getInfos();
    this.onChangePage.emit('info');
    window.scrollTo(0, 0);
  }

  getInfos(): void {
    this.gemsService.getInfos()
    .subscribe(
      infos => this.infos = infos
    );
  }

  goTo(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
