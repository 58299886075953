import { Component } from '@angular/core';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Gems';
  component = 'home';

  changeSelectedMenuItem(menuitem: string) {
    this.component = menuitem;
    this.title = menuitem;
  }
}
