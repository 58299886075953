<div class="rd-mobilemenu" id="mobile-menu">
  <ul class="rd-mobilemenu_ul">
    <li><a href="https://kalender.gemsdiepenbeek.be/live.php?page=live" target="_blank" title="Live scores"><div class="live-icon hide-menu-item" style="padding-top: 10px; background-position-x: right;">nu live</div></a></li>
    <li><a routerLink="/home">Home</a></li>
    <li><a routerLink="/ploegen">Ploegen</a></li>
    <li><a routerLink="/club">Club</a></li>
    <li><a routerLink="/sponsors">Sponsors</a></li>
    <li><a routerLink="/info">Info</a></li>
    <li><a routerLink="/wordlid">Leden</a></li>
  </ul>
</div>
<div class="rd-mobilepanel">
  <button class="rd-mobilepanel_toggle"><span></span></button>
  <h2 class="rd-mobilepanel_title">{{title}}</h2>
  <img src="assets/images/logo-gems.png" style="height: 40px; margin-top: 10px; margin-right: 10px; float: right;">
  <a href="https://kalender.gemsdiepenbeek.be/live.php?page=live" target="_blank"><div class="live-icon" style="margin-top: 0;">nu live</div></a>
</div>
<header>
    <section class="bg-contrast-variant-1" id="menu-section">
        <div _ngcontent-sil-c50="" id="stuck_container" class="stuck_container border-bottom isStuck" style="top: 0px; visibility: visible; position: fixed; width: 100%; margin-top: 0px;">
          <div _ngcontent-sil-c50="" class="container">
            <div _ngcontent-sil-c50="" class="brand">
              <a _ngcontent-sil-c50="" href="./"><img _ngcontent-sil-c50="" src="assets/images/logo-gems.png" alt="" style="height: 70px;"></a>
            </div>
            <nav _ngcontent-sil-c50="" id="navbar" class="nav">
              <ul _ngcontent-sil-c50="" data-type="navbar" class="sf-menu sf-js-enabled sf-arrows">
                <li class="hide-menu-item">
                  <a href="https://kalender.gemsdiepenbeek.be/live.php?page=live" target="_blank" title="Live scores">
                    <div class="live-icon hide-menu-item" style="padding-top: 10px; background-position-x: right;">nu live</div>
                  </a>
                </li>
                <li id="home-menu-item" routerLinkActive="active">
                    <a routerLink="/home">Home</a>
                </li>
                <li id="covid19-menu-item" routerLinkActive="active">
                    <a routerLink="/covid19">Covid-19</a>
                </li>
                <li id="ploegen-menu-item" routerLinkActive="active">
                    <a routerLink="/ploegen">Ploegen</a>
                </li>
                <li id="club-menu-item" routerLinkActive="active">
                    <a routerLink="/club">Club</a>
                </li>
                <!-- <li id="fotos-menu-item">
                    <a href="#/fotos">Foto's</a>
                </li> -->
                <li id="sponsors-menu-item" routerLinkActive="active">
                    <a routerLink="/sponsors">Sponsors</a>
                </li>
                <li id="info-menu-item" routerLinkActive="active">
                    <a routerLink="/info">Info</a>
                </li>
                <!-- <li id="vacatures-menu-item">
                    <a href="#/vacatures">Vacatures</a>
                </li> -->
                <li id="leden-menu-item" routerLinkActive="active">
                    <a routerLink="/wordlid">Word lid</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

    </section>
</header>
<main>
  <router-outlet></router-outlet>
  <!-- <app-home (onChangePage)="changeSelectedMenuItem($event)"></app-home> -->
</main>
