import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PloegenComponent } from './ploegen/ploegen.component';
import { PloegComponent } from './ploeg/ploeg.component';
import { ClubComponent } from './club/club.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { InfoComponent } from './info/info.component';
import { WordLidComponent } from './wordlid/wordlid.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PloegenComponent,
    PloegComponent,
    ClubComponent,
    SponsorsComponent,
    InfoComponent,
    WordLidComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
