<section class="well well--inset-2 bg-default-variant-1">
    <div class="container">
        <h3>Ploegen</h3>

        <div class="row flow-offset-1">
            <div *ngFor="let ploeg of ploegen" class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-4" style="cursor: pointer;">
                <div class="box-skin-2" style="cursor: pointer;" [routerLink]="'/ploegen/' + ploeg.id">
                    <img [src]="ploeg.foto" alt="">

                    <div>
                        <p>{{ploeg.type}}</p>

                        <p class="small light">{{ploeg.naam}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
