import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Ploeg } from '@app/_models';
import { GemsService } from '@app/_services';

@Component({
  selector: 'app-ploegen',
  templateUrl: './ploegen.component.html',
  styleUrls: ['./ploegen.component.css']
})
export class PloegenComponent implements OnInit {
  ploegen: Ploeg[];

  constructor(private gemsService: GemsService) { }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    this.getPloegen();
    this.onChangePage.emit('ploegen');
    window.scrollTo(0, 0);
  }

  getPloegen(): void {
    this.gemsService.getPloegen()
    .subscribe(
      ploegen => this.ploegen = ploegen
    );
  }

  goToPloeg(team): void {

  }

}
