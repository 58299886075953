<section class="parallax well text-center submenu" style="padding-bottom: 30px; background-image: url('assets/images/38-back.jpg')">
    <div class="container">
        <div class="row" *ngIf="ploeg !== null && ploeg.klassement !== null && ploeg.klassement">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('klassement')" class="btn btn-sm btn-primary">Klassement</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3" *ngIf="ploeg.kalender">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('kalender')" class="btn btn-sm btn-primary">Kalender</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3" *ngIf="ploeg.toonTrainingsuren">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('trainingsuren')" class="btn btn-sm btn-primary">Trainingsuren</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('ploeg')" class="btn btn-sm btn-primary">Ploeg</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-3 offset-3" *ngIf="!ploeg.toonSpelers && ploeg.toonCoaches">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('ploeg')" class="btn btn-sm btn-primary">Coaches</a>
                </div>
            </div>
        </div>
        <div class="row" [hidden]="ploeg.klassement === true" style="margin-top: 0;">
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4 offset-3" *ngIf="ploeg.kalender">
                <div class="event-post" style="cursor: pointer;">
                    <a (click)="goTo('kalender')" class="btn btn-sm btn-primary">Kalender</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4 offset-3" *ngIf="ploeg.info != null">
                <div class="event-post" style="cursor: pointer;">
                    <a (click)="goTo('info')" class="btn btn-sm btn-primary">Info</a>
                </div>
            </div>
            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4 offset-3" *ngIf="ploeg.toonTrainingsuren">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('trainingsuren')" class="btn btn-sm btn-primary">Trainingsuren</a>
                </div>
            </div>

            <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4 offset-3">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a (click)="goTo('ploeg')" class="btn btn-sm btn-primary">Ploeg</a>
                </div>
            </div>
            <!-- <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-4 offset-3" *ngIf="!ploeg.toonSpelers && ploeg.toonCoaches">
                <div class="event-post" style="margin-bottom: 10px; cursor: pointer;">
                    <a ng-click="goTo('ploeg');" class="btn btn-sm btn-primary">Coaches</a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<section class="well well--inset-5" *ngIf="ploeg.info != null" id="info">
    <div class="container" [innerHTML]="ploeg.info">
    </div>
</section>
<section class="well" data-mobile="true" *ngIf="stand?.length != 0 && ploeg.klassement" id="klassement">
    <div class="container">
        <h3>Klassement <span>{{ploeg['naam']}}</span></h3>
        <div class="row offset-1 flow-offset-2">
            <div class="table-responsive">
                <table class="rwd-table">
                        <tr>
                            <th>Nr.</th>
                            <th>Ploeg</th>
                            <th class="min-width-500">Gespeeld</th>
                            <th class="min-width-500">Score +</th>
                            <th class="min-width-500">Score -</th>
                            <th>Punten</th>
                        </tr>
                    <tr class="nodd" *ngFor="let club of stand">
                        <td>{{club.nummer}}</td>
                        <td style="font-weight: bold;" *ngIf="club.thuisTeam">{{club.naam}}</td>
                        <td *ngIf="!club.thuisTeam">{{club.naam}}</td>
                        <td class="min-width-500">{{club.aantal}}</td>
                        <td class="min-width-500">{{club.puntenVoor}}</td>
                        <td class="min-width-500">{{club.puntenTegen}}</td>
                        <td>{{club.punten}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</section>
<section class="well" data-mobile="true" *ngIf="wedstrijden?.length != 0 && ploeg.kalender" id="kalender">
    <div class="container">
        <h3>
          Kalender <span>{{ploeg.naam}}</span>
          <a [href]="sanitize('webcal://kalender.gemsdiepenbeek.be/abonneer.php?id=' + ploeg.id)" target="_blank" style="float: right; margin-right: 10px; margin-bottom: 5px; margin-top: 5px;"><img src="https://kalender.gemsdiepenbeek.be/ical.png" style="height: 30px;" title="Abonneer op iCal kalender"/></a>
      		<a [href]="sanitize('https://www.google.com/calendar/render?cid=http://kalender.gemsdiepenbeek.be/abonneer.php?id=' + ploeg.id)" target="_blank" style="float: right; margin-right: 10px; margin-bottom: 5px; margin-top: 5px;"><img src="https://kalender.gemsdiepenbeek.be/google-calendar.png" style="height: 30px;" title="Abonneer op Google kalender"/></a>
        </h3>
        <div class="row offset-1 flow-offset-2">
            <div class="table-responsive">
                <table class="rwd-table">
                    <tr>
                        <th>Datum</th>
                        <th>Tijd</th>
                        <th>Thuis</th>
                        <th>Uit</th>
                        <th class="min-width-500">Uitslag</th>
                        <th class="min-width-500">Sporthal</th>
                    </tr>
                    <tr class="nodd" *ngFor="let wedstrijd of wedstrijden">
                        <td class="centertext">{{wedstrijd.datum}}</td>
                        <td>{{wedstrijd.uur}}</td>
                        <td style="font-weight: bold;" *ngIf="wedstrijd.thuisWedstrijd">{{wedstrijd.thuisTeam}}</td>
                        <td *ngIf="!wedstrijd.thuisWedstrijd">{{wedstrijd.thuisTeam}}</td>
                        <td style="font-weight: bold;" *ngIf="!wedstrijd.thuisWedstrijd">{{wedstrijd.uitTeam}}</td>
                        <td *ngIf="wedstrijd.thuisWedstrijd">{{wedstrijd.uitTeam}}</td>
                        <td class="min-width-500">{{wedstrijd.uitslag}}</td>
                        <td class="min-width-500"><a [href]="'https://kalender.gemsdiepenbeek.be/sporthal.php?id=' + wedstrijd.guid" target="_blank"><img style="height: 20px;" src="assets/images/location.png"/></a></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</section>
<section class="well" data-mobile="true" id="trainingsuren" *ngIf="ploeg.toonTrainingsuren && trainingsuren !== undefined && trainingsuren.length !== 0">
    <div class="container">
        <h3>Trainingsuren <span>{{ploeg['naam']}}</span></h3>
        <div class="row offset-1 flow-offset-2">
            <div class="table-responsive">
                <table class="rwd-table">
                        <tr>
                            <th>Dag</th>
                            <th>Startuur</th>
                            <th>Einduur</th>
                            <th>Sporthal</th>
                        </tr>
                    <tr class="nodd" *ngFor="let traingsuur of trainingsuren">
                        <td>{{traingsuur.dag}}</td>
                        <td>{{traingsuur.startUur}}</td>
                        <td>{{traingsuur.eindUur}}</td>
                        <td>{{traingsuur.sporthal}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</section>
<section class="well well--inset-2 bg-default-variant-1" id="ploeg">
    <div class="container">
        <h3><span *ngIf="ploeg.toonSpelers && ploeg.toonCoaches">Ploeg {{ploeg['naam']}}</span></h3>
        <div class="row flow-offset-1">
            <img [src]="ploeg.foto"/>
        </div>
        <div class="row flow-offset-1" *ngIf="ploeg.toonSpelers || ploeg.toonCoaches">
            <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3" *ngFor="let coach of ploeg.coaches" [hidden]="!ploeg.toonCoaches">
                <div class="box-skin-2">
                    <img [src]="coach.foto" alt="">

                    <div>
                        <p>{{coach.voornaam}} {{coach.naam}}</p>

                        <p class="small light">{{coach.functie}}</p>
                        <p class="small light">
                          <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                          <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">{{coach.telefoon}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xs-offset-3 col-xs-6 col-sm-offset-0 col-sm-6 col-md-3" *ngFor="let speler of ploeg.spelers" [hidden]="!ploeg.toonSpelers">
                <div class="box-skin-2">
                    <img [src]="speler.foto" alt="">

                    <div>
                        <p>{{speler.voornaam}} {{speler.naam}}</p>

                        <p class="small light">{{speler.geboortejaar}}</p>
                        <p class="small light" style="height: 34px;">Lidnummer: {{speler.lidnummer}}</p>
                          <!-- <span class="icon icon-sm icon-secondary fa-phone" style="color: #fff;"></span>
                          <span style="vertical-align: middle; line-height: 30px; margin-left: 10px;">fff</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
