import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { NewsItem } from '@app/_models';
import { Match } from '@app/_models';
import { Activity } from '@app/_models';
import { Sponsor } from '@app/_models';
import { Ploeg } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class GemsService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient) { }

  getNewsItems(): Observable<NewsItem[]> {
    return this.http.get<NewsItem[]>('https://www.gemsdiepenbeek.be/api/newsitems.php')
      .pipe(
        catchError(this.handleError<NewsItem[]>('getNewsItems', []))
      );
  }

  getMatches(date): Observable<Match[]> {
    return this.http.get<Match[]>('https://www.gemsdiepenbeek.be/api/matches.php' + (date != null ? ('?date=' + date) : ''))
      .pipe(
        catchError(this.handleError<Match[]>('getMatches', []))
      );
  }

  getPloeg(ploegId): Observable<Ploeg> {
    return this.http.get<Ploeg>('https://www.gemsdiepenbeek.be/api/ploeg.php?id=' + ploegId)
      .pipe(
        catchError(this.handleError<Ploeg>('getPloeg', null))
      );
  }

  getTrainingsuren(ploegId): Observable<[]> {
    return this.http.get<[]>('https://www.gemsdiepenbeek.be/api/trainingsuren.php?ploegId=' + ploegId)
      .pipe(
        catchError(this.handleError<[]>('getTrainingsuren', []))
      );
  }

  getWedstrijden(ploegId): Observable<[]> {
    return this.http.get<[]>('https://www.gemsdiepenbeek.be/api/ploeg-matches.php?ploegId=' + ploegId)
      .pipe(
        catchError(this.handleError<[]>('getWedstrijden', []))
      );
  }

  getStand(ploegId): Observable<[]> {
    return this.http.get<[]>('https://www.gemsdiepenbeek.be/api/stand.php?ploegId=' + ploegId)
      .pipe(
        catchError(this.handleError<[]>('getStand', []))
      );
  }

  getActivities(): Observable<Activity[]> {
    return this.http.get<Activity[]>('https://www.gemsdiepenbeek.be/api/activiteiten.php')
      .pipe(
        catchError(this.handleError<Activity[]>('getActivities', []))
      );
  }

  getSponsors(): Observable<Sponsor[]> {
    return this.http.get<Sponsor[]>('https://www.gemsdiepenbeek.be/api/sponsors.php?all=true')
      .pipe(
        catchError(this.handleError<Sponsor[]>('getSponsors', []))
      );
  }

  getHoofdSponsors(): Observable<Sponsor[]> {
    return this.http.get<Sponsor[]>('https://www.gemsdiepenbeek.be/api/sponsors.php')
      .pipe(
        catchError(this.handleError<Sponsor[]>('getHoofdSponsors', []))
      );
  }

  getCoSponsors(): Observable<Sponsor[]> {
    return this.http.get<Sponsor[]>('https://www.gemsdiepenbeek.be/api/sponsors.php?co=true')
      .pipe(
        catchError(this.handleError<Sponsor[]>('getCoSponsors', []))
      );
  }

  getRandomHoofdsponsors(): Observable<Sponsor[]> {
    return this.http.get<Sponsor[]>('https://www.gemsdiepenbeek.be/api/random-hoofdsponsors.php')
      .pipe(
        catchError(this.handleError<Sponsor[]>('getRandomHoofdsponsors', []))
      );
  }

  getPloegen(): Observable<Ploeg[]> {
    return this.http.get<Ploeg[]>('https://www.gemsdiepenbeek.be/api/ploegen.php')
      .pipe(
        catchError(this.handleError<Ploeg[]>('getPloegen', []))
      );
  }

  getSponsoring(): Observable<{}> {
    return this.http.get<{}>('https://www.gemsdiepenbeek.be/api/sponsoring.php')
      .pipe(
        catchError(this.handleError<{}>('getSponsoring', []))
      );
  }

  getInfos(): Observable<[]> {
    return this.http.get<[]>('https://www.gemsdiepenbeek.be/api/infos.php')
      .pipe(
        catchError(this.handleError<[]>('getInfos', []))
      );
  }

  sendSponsorForm(response, naam, voornaam, bedrijf, gsm, email): {} {
    return {};
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
