import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GemsService } from '@app/_services';
import { Ploeg, Match, Coach, Speler, Trainingsuur } from '@app/_models';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-ploeg',
  templateUrl: './ploeg.component.html',
  styleUrls: ['./ploeg.component.css']
})
export class PloegComponent implements OnInit {
  ploegId: string;
  ploeg: Ploeg = {id: 0,
    naam: '',
    info: '',
    klassement: false,
    kalender: false,
    toonSpelers: false,
    toonCoaches: false,
    toonTrainingsuren: false,
    type: '',
    foto: '',
    coaches: [],
    spelers: []
  };
  stand: [];
  wedstrijden: Match[];
  trainingsuren: Trainingsuur[];

  constructor(private gemsService: GemsService, private actRoute: ActivatedRoute, private sanitizer: DomSanitizer, private viewportScroller: ViewportScroller) {
    this.ploegId = this.actRoute.snapshot.params.ploegId;
  }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    console.log(this.ploegId);
    this.getPloeg();
    this.getStand();
    this.getWedstrijden();
    this.getTrainingsuren();
    this.onChangePage.emit('ploegen');
    window.scrollTo(0, 0);
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getPloeg(): void {
    this.gemsService.getPloeg(this.ploegId)
    .subscribe(
      ploeg => this.ploeg = ploeg
    );
  }

  getStand(): void {
    this.gemsService.getStand(this.ploegId)
    .subscribe(
      stand => this.stand = stand
    );
  }

  getWedstrijden(): void {
    this.gemsService.getWedstrijden(this.ploegId)
    .subscribe(
      wedstrijden => this.wedstrijden = wedstrijden
    );
  }

  getTrainingsuren(): void {
    this.gemsService.getTrainingsuren(this.ploegId)
    .subscribe(
      trainingsuren => this.trainingsuren = trainingsuren
    );
  }

  goTo(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
