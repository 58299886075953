import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GemsService } from '@app/_services';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.css']
})
export class ClubComponent implements OnInit {

  constructor(private gemsService: GemsService, private viewportScroller: ViewportScroller) { }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    this.onChangePage.emit('club');
    window.scrollTo(0, 0);
  }

  goTo(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
