import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NewsItem, Match, Activity, Sponsor, Ploeg } from '@app/_models';
import { GemsService } from '@app/_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  newsitems: NewsItem[];
  matches: Match[];
  activiteiten: Activity[];
  sponsors: Sponsor[];
  randomHoofdsponsors: Sponsor[];
  firstNewsitem: NewsItem;
  secondNewsitem: NewsItem;
  dateObject: Date = new Date();
  date: string;

  constructor(private gemsService: GemsService) { }

  @Output() onChangePage = new EventEmitter<string>();

  ngOnInit() {
    this.fillDate();
    this.getNewsItems();
    this.getMatches();
    this.getActivities();
    this.getSponsors();
    this.getRandomHoofdsponsors();
    this.onChangePage.emit('home');
    window.scrollTo(0, 0);
  }

  openSponsorPage(sponsor): void {
      window.open(sponsor.website, '_blank');
  }

  getNewsItems(): void {
    this.gemsService.getNewsItems()
    .subscribe(
      newsitems => {
        this.newsitems = newsitems;
        this.firstNewsitem = newsitems[0];
        this.secondNewsitem = newsitems[1];
    });
  }

  getMatches(): void {
    this.gemsService.getMatches(this.date)
    .subscribe(
      matches => this.matches = matches
    );
  }

  getActivities(): void {
    this.gemsService.getActivities()
    .subscribe(
      activiteiten => this.activiteiten = activiteiten
    );
  }

  getSponsors(): void {
    this.gemsService.getSponsors()
    .subscribe(
      sponsors => this.sponsors = sponsors
    );
  }

  getRandomHoofdsponsors(): void {
    this.gemsService.getRandomHoofdsponsors()
    .subscribe(
      randomHoofdsponsors => this.randomHoofdsponsors = randomHoofdsponsors
    );
  }

  fillDate() {
    var month = this.dateObject.getMonth() + 1;
    var day = this.dateObject.getDate();
    var year = this.dateObject.getFullYear();
    this.date = year + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + day;
  }

  previousWeek(): void {
    this.dateObject.setDate(this.dateObject.getDate() - 7);
    this.fillDate();
    this.getMatches();
  }

  nextWeek(): void {
    this.dateObject.setDate(this.dateObject.getDate() + 7);
    this.fillDate();
    this.getMatches();
  }

  goTo(newsitem): void {

  }

}
